.heroMedia{
    background: url("./images/gradient-box.jpg") no-repeat right bottom;
    cursor: pointer;
    margin-top: 10px;
    padding: 0 53px 48px 0;
}
.heroMedia .thumb_container{
    background-size: cover;
    position: relative;
    height: 295px;
    width: 524px;
}
.heroMedia .play{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 120px;
    width: 120px;
}

@media only screen and (max-width: 769px) {
    .heroMedia{padding: 0 20px 20px 0}
    .heroMedia .thumb_container{ width: 100% }
}

.limit .heroMedia{padding: 0 20px 20px 0}
.limit .heroMedia .thumb_container{ width: 100% }