@font-face {
  font-family: "MetricHPE";
  src: url("./assets/fonts/MetricHPE-Web-Regular.eot");
  src: url("./assets/fonts/MetricHPE-Web-Regular.woff") format("woff"),
  url("./assets/fonts/MetricHPE-Regular.otf") format("opentype");
}
@font-face {
  font-family: "MetricHPE-Medium";
  src: url("./assets/fonts/MetricHPE-Web-Medium.eot");
  src: url("./assets/fonts/MetricHPE-Web-Medium.woff") format("woff"),
  url("./assets/fonts/MetricHPE-Medium.otf") format("opentype");
}
@font-face {
  font-family: "MetricHPE-Bold";
  src: url("./assets/fonts/MetricHPE-Web-Bold.eot");
  src: url("./assets/fonts/MetricHPE-Web-Bold.woff") format("woff"),
  url("./assets/fonts/MetricHPE-Bold.otf") format("opentype");
}
@font-face {
  font-family: "MetricHPE-Semibold";
  src: url("./assets/fonts/MetricHPE-Web-Semibold.eot");
  src: url("./assets/fonts/MetricHPE-Web-Semibold.woff") format("woff"),
  url("./assets/fonts/MetricHPE-Semibold.otf") format("opentype");
}
@font-face {
  font-family: "MetricHPE-Light";
  src: url("./assets/fonts/MetricHPE-Web-Light.eot");
  src: url("./assets/fonts/MetricHPE-Web-Light.woff") format("woff"),
  url("./assets/fonts/MetricHPE-Light.otf") format("opentype");
}
body{font-family: "MetricHPE"; font-size: 16px; scroll-behavior: smooth;}
ul{margin:0; padding:0; list-style: none}

/***** Scrollbar ******/

/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 0.7em 0 #E8E8E8, 0 0 1em 0 #E8E8E8;*/
  border-radius: 12px;
} 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #E8E8E8;
  border-radius: 12px;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}