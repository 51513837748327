img {
    max-width: 100%;
    height: auto;
}
main{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    gap: 1.5rem;
}
.vertical{
    padding: 0 48px;
    margin-bottom: 30px;
}
.vertical ul{padding: 0}

section h1{
    font-family: "MetricHPE-Semibold";
    font-size: 4rem;
    line-height: 4rem;
    margin: 0 0 48px;
    padding-bottom: 40px;
    position: relative;
}
section h1::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 176px;
    left: 0;
    border-top: 9px solid #01A982;
}

section p{
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 3rem;
}

.hero{margin-bottom: 30px}
.hero img{width: 100%}

.menu{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

.menu li{
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    padding: 24px 20px;
    position: relative;
}
.menu li a{color: #fff; text-decoration: none;}

.menu li.edge{background: linear-gradient(223.56deg, #FF7A00 -44.63%, #FF8200 -12.11%, #FFA900 18.66%, #FFC200 28.23%, #FFCB00 32.86%, #F9CA02 34.73%, #E8C80B 43.39%, #CDC418 53.49%, #A7C02B 65.03%, #77BA43 76.57%, #3CB360 88.12%, #00AC7F 99.66%);}
.menu li.data{background: linear-gradient(224.8deg, #00DEC8 -0.69%, #D221FF 99.66%);}
.menu li.cloud{background: linear-gradient(224.73deg, #8219F3 0.23%, #EB3474 36.29%, #FFC800 99.31%);}
.menu li.security{background: linear-gradient(224.8deg, #8219F3 -0.69%, #6E36EC 6.34%, #3390D9 26.41%, #0EC8CC 41.46%, #00DEC8 48.48%, #13DCB8 54.5%, #47D78F 65.54%, #9AD04E 81.59%, #FFC800 99.66%);}

.menu li span{
    font-size: 1.16rem;
    line-height: 2rem;
}

.menu li h2{
    font-size: 1.67rem;
    line-height: 1.5rem;
    margin: 0;
}
.menu li:hover h2{
    text-decoration: underline;
}
.menu li h2 strong{
    font-family: "MetricHPE-Semibold";
}

.arrowButton{
    position: absolute;
    bottom: 13px;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    background: url("./images/white-arrow.svg") no-repeat 0 0;
    width: 33px;
    height: 24px;
}

@media only screen and (max-width: 769px) {
    .vertical section h1{
        font-size: 3.5rem;
    }
    .menu{
        grid-template-columns: repeat(1, 1fr);
    }   
    .vertical .menu li{
        padding: 15px;
        padding-bottom: 45px;
    }
}

.limit .vertical section h1{
    font-size: 3.5rem;
}
.limit .menu{
    grid-template-columns: repeat(1, 1fr);
}   
.limit .vertical .menu li{
    padding: 15px;
    padding-bottom: 45px;
}