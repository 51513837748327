.network{
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 50px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;

    -webkit-animation: fadein 1.3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1.3s; /* Firefox < 16 */
        -ms-animation: fadein 1.3s; /* Internet Explorer */
         -o-animation: fadein 1.3s; /* Opera < 12.1 */
            animation: fadein 1.3s;
}
.network__close{
    background: #00B388;
    border: none;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    font-size: 30px;
    height: 63px;
    position: absolute;
    top: 50px;
    right: 50px;
    width: 63px;
}
.network__container{
    overflow-y: auto;
    margin-top: 100px;
    margin-right: 27px;
    max-height: 90%;
    width: 100%;
    padding: 0 45px;

    display: grid;
    grid-template-columns: repeat(auto-fit, 440px);
    grid-row-gap: 45px;
    justify-content: space-between;
}

.network__hero{
    background: top right;
    margin-bottom: 30px;
    padding: 0;
    width: 100%;
}
.network__container .network__hero{
    grid-column: 1 / -1;
}

.network__hero .content{
    background: #fff;
    color: #000;
    margin-left: 50px;
    min-height: 500px;
    padding: 30px;
    width: 500px;
}

.network__hero .content h1{
    font-family: "MetricHPE";
    font-size: 1.7rem;
    margin: 0;
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.network__hero .content h1:after {
    background: #01A982;
    bottom: 0;
    content: "";
    height: 4px;
    left: 0;
    position: absolute;
    width: 90px;
}

.network__container .linksBoxItem{
    margin: 0;
}