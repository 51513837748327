.videoPlayer{
    background: url("../../../public/background.jpg") repeat 0 0;
    color: #000;
    position:fixed;
    top:0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 3;

    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
}
.videoPlayer header.videoPlayer__header{
    background-color: transparent;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 100%;
    padding: 0;
    margin: 48px 48px 0;
    min-height: 76px;
}
.videoPlayer__header .videoPlayer__titleBox{
    display: flex;
    align-items: flex-start;
}
.videoPlayer__header .videoPlayer__titleBox h3.videoPlayer__header{
    font-family: "MetricHPE-Light";
    font-size: 1.5rem;
    margin: 0;
    margin-right: 33px;
    max-width: 560px;
    text-align: right;
}
.videoPlayer__back{
    background: transparent url("../../assets/images/arrow-back.svg") no-repeat 0 0;
    border: none;
    color: #000;
    cursor: pointer;
    font-size: 30px;
    height: 28px;
    text-indent: -100000px;
    width: 31px;
}
.videoPlayer__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-top: 23px;
    height: calc(100vh - 195px);
    max-width: 100%;
    padding: 0;
    margin: 23px 48px 0 48px;
}
.videoPlayer__container .videoContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-top: 23px;
    height: calc(100vh - 195px);
    max-width: 100%;
    padding: 0;
    margin: 23px 48px 0 48px;
}
.videoPlayer__container .gradientContainer{
    position: relative;
    height: 100%;
    margin-right: 24px;
    width: calc(100% - 24px);
}
.videoPlayer__container .gradientContainer::after{
    content: "";
    background: linear-gradient(223.56deg, #FF7A00 -44.63%, #FF8200 -12.11%, #FFA900 18.66%, #FFC200 28.23%, #FFCB00 32.86%, #F9CA02 34.73%, #E8C80B 43.39%, #CDC418 53.49%, #A7C02B 65.03%, #77BA43 76.57%, #3CB360 88.12%, #00AC7F 99.66%);
    height: 100%;
    position: absolute;
    right: -24px;
    bottom: -24px;
    width: 100%;
    z-index: -1;
}
.videoPlayer__container .gradientContainer video{
    background: #000;
    position: relative;
    height: 100%;
    width: 100%;
}
.videoPlayer__container .disclaimer{
    display: flex;
    margin-top: 32px;
}
.videoPlayer__container .disclaimer .qrcode{
    margin-right: 40px;
}
.videoPlayer__container .disclaimer h3.videoPlayer__header{
    font-family: "MetricHPE";
    font-size: 2.5rem;
    margin: 0;
    max-width: 853px;
}
.videoPlayer h4{
    font-size: 15px;
    margin-top: 10px;
}
video::cue{
    background-color: transparent;
    color: #FFF;
    font-size: 34px;
    font-family: "MetricHPE";
}