.pdf{
    background: url("../../../public/background.jpg") repeat 0 0;
    color: #000;
    position:fixed;
    top:0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 3;

    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
}
.pdf header.pdf__header{
    background-color: transparent;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 100%;
    padding: 0;
    margin: 48px 48px 0;
    min-height: 76px;
}
.pdf__header .pdf__titleBox{
    display: flex;
    align-items: flex-start ;
}
.pdf__header .pdf__titleBox h3.pdf__header{
    font-family: "MetricHPE-Light";
    font-size: 1.5rem;
    margin: 0;
    margin-right: 33px;
    max-width: 560px;
}
.pdf__back{
    background: transparent url("../../assets/images/arrow-back.svg") no-repeat 0 0;
    border: none;
    color: #000;
    cursor: pointer;
    font-size: 30px;
    height: 28px;
    text-indent: -100000px;
    width: 31px;
}
.pdf__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-top: 23px;
    height: calc(100vh - 195px);
    max-width: 100%;
    padding: 0;
    margin: 23px 48px 0 48px;
}
.pdf__container .clickContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}
.pdf__container .gradientContainer{
    position: relative;
    height: 100%;
    margin-right: 24px;
    width: calc(100% - 24px);
}
.pdf__container .gradientContainer::after{
    content: "";
    background: linear-gradient(223.56deg, #FF7A00 -44.63%, #FF8200 -12.11%, #FFA900 18.66%, #FFC200 28.23%, #FFCB00 32.86%, #F9CA02 34.73%, #E8C80B 43.39%, #CDC418 53.49%, #A7C02B 65.03%, #77BA43 76.57%, #3CB360 88.12%, #00AC7F 99.66%);
    height: 100%;
    position: absolute;
    right: -24px;
    bottom: -24px;
    width: 100%;
    z-index: -1;
}
.pdf__container .gradientContainer iframe{
    border: 1px solid #474b4d;
    border-bottom: 7px solid #474b4d;
    height: 100%;
}
.react-pdf__Document {
    border-radius: 8px;
    position: relative;
    height: 100%;
    width: 100%;
}
.react-pdf__Page,
.react-pdf__Page__canvas{
    height: 100%;
}
.react-pdf__Page__annotations{display:none}
.react-pdf__Page__canvas{border-radius: 8px;}

.pdf__disclaimer{
    display: flex;
    margin-top: 60px;
    width: 100%;
}
.pdf:hover .page-controls {
    opacity: 1;
}
.page-controls{
    display: flex;
    margin: 55px 0;
}
.page-controls span {
    color: #000;
    display: flex;
    align-items: center;
    font-family: "MetricHPE-Light";
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 0 0.5em;
}
.page-controls button {
    border: 0;
    background-color: transparent;
    color: #01A982;
    font-family: "MetricHPE-Light";
    font-size: 1.5rem;
    line-height: 2rem;
    width: 120px;
}
.page-controls button:disabled{
    color: #bdbdbd;
}
.page-controls button:enabled:hover{
    cursor: pointer;
}
.iframe{border:none;}