:root {
    --clr-neon: #01a982;
}
.h.container{
    display: flex;
    height: 100vh;
    position: relative;
}
.h.container.fade-out{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}
.loading{
    min-height: 100vh;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 2;
}
.progress {
    width: 100%;
    max-width: 30%;
    height: 30px;
    background: #231F20;
    border: var(--clr-neon) 2px solid;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon), 0 0 0.5em 0 var(--clr-neon);
    position: relative;
  }
  .progress::before {
    pointer-events: none;
    content: "";
    position: absolute;
    background: #01a982;
    top: 120%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
    filter: blur(1em);
    opacity: 0.7;
  }
  .progress::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 2em 0.5em var(--clr-neon);
    opacity: 0;
    background-color: var(--clr-neon);
    z-index: -1;
    transition: opacity 100ms linear;
  }
  .progress .progress-bar {
    display: block;
    height: 100%;
    background:  #01a982;
    background-size: 300% 100%;
    -webkit-animation: progress-animation 2s linear infinite;
            animation: progress-animation 2s linear infinite;
  }
  
  @-webkit-keyframes progress-animation {
    0% {
      background-position: 100%;
    }
    100% {
      background-position: 0;
    }
  }
  
  @keyframes progress-animation {
    0% {
      background-position: 100%;
    }
    100% {
      background-position: 0;
    }
  }
.unity{
    flex-grow: 1;
    height: 100%;
    position: relative;
    width: 100%;
}
.unity canvas{
    display: block;
    height: 100%;
    width: 100%;
}
.searchbtn{
  background: #01A982 url('search-icon.svg') no-repeat center center;
  background-size: 16px;
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display:block;
  height: 42px;
  text-indent: -15000px;
  overflow: hidden;
  width: 42px;
  z-index: 1;
}
.h.container .overUnity{
  position: absolute;
  padding: 21px 48px 48px 48px;
  pointer-events: none;
  width: 100%;
  z-index: 2;
  -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.h.container .overUnity header{
  display: flex;
  justify-content: space-between;
}
.h.container .overUnity header .logoBox{
  background: rgba(255, 255, 255, 0.7);
  border-radius: 12px 12px 0 0;
  padding: 27px;
  width: 300px;
}
.h.container .overUnity header .logoBox .logo{
  width: 140px;
}
.h.container .overUnity header nav{
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: auto;
  margin-top: -36px;
}
.h.container .overUnity .intro{
  background: rgba(255, 255, 255, 0.7);
  border-radius: 0 0 12px 12px;
  padding: 27px;
  width: 300px;
}
.h.container .overUnity .intro h1{
  font-family: "MetricHPE-Semibold";
  font-size: 2rem;
  line-height: 2rem;
}
.h.container .overUnity .intro .content-container{
  font-size: 1.5rem;
  line-height: 2rem;
}
.h.container .overUnity .intro .content-container p{
  margin: 0;
}
.allverticals{
  background: #fff;
  border: 1px solid #000;
  border-radius: 2.667px;
  cursor: pointer;
  font-family: "MetricHPE-Medium";
  font-size: 1rem;
  padding: 5.333px;
  margin-right: 20px;
}
.allverticals:hover{
  border: 0.667px solid #50B08B;
  background: #01A982;
  color: #fff;
}