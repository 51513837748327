.linksBox{
    background: url("../../../public/background.jpg") repeat 0 0;
    color: #000;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 4;

    -webkit-animation: fadein 1.3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1.3s; /* Firefox < 16 */
        -ms-animation: fadein 1.3s; /* Internet Explorer */
         -o-animation: fadein 1.3s; /* Opera < 12.1 */
            animation: fadein 1.3s;
}
.linksBox__close{
    background: #00B388;
    border: none;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    font-size: 30px;
    height: 63px;
    position: absolute;
    top: 50px;
    right: 50px;
    width: 63px;
}
.linksBox__container{
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: auto;
    margin-top: 100px;
    max-height: 80%;
    max-width: 91%
}