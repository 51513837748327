.ctacomponent{
    margin-top: 40px;
}
.ctacomponent .cta{
    background: url("../linksBox/images/icon-pdf.svg") no-repeat 2px 5px;
    color: #01A982;
    font-family: 'MetricHPE-Bold';
    font-size:  1.875rem;
    line-height: 2.25rem;
    padding-left: 40px;
}
.ctacomponent  .cta.pdfbtn{
    background-image: url("../linksBox/images/icon-pdf.svg");
}
.ctacomponent  .cta.videobtn{
    background-image: url("../linksBox/images/icon-video.svg");
}