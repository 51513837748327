
.pillar.need .container{
    background-color: #fff;
    padding: 0px 48px; 
}
.pillar .container.fade-in {
    -webkit-animation: fade-in 1.3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fade-in 1.3s; /* Firefox < 16 */
        -ms-animation: fade-in 1.3s; /* Internet Explorer */
         -o-animation: fade-in 1.3s; /* Opera < 12.1 */
            animation: fade-in 1.3s;
}
@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1 !important; }
}
.pillar.need .container .content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin: 0 auto;
}
.pillar .container .content img{margin-top:10px}
.pillar.need .container .content h3.title{
    font-size: 3.5rem;
    line-height: 4rem;
    font-family: "MetricHPE-Semibold";
    margin: 0 0 48px;
    padding-bottom: 40px;
    position: relative;
}
.pillar .container .content h3.title::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 176px;
    left: 0;
    border-top: 9px solid #01A982;
}
.pillar .container .content-container ul{
    padding-left: 15px;
    list-style: disc;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2.5rem;
}
.pillar .container .content p{
    font-size: 1.5rem;
    line-height: 2rem;
}
.pillar .container .content .hero{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.pillar .container .content .hero.mobile{display: none;}
.pillar .container .content .hero img{
    max-width: 580px;
}

.pillar .container .links{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin: 0 auto;
}
@media only screen and (min-width: 1281px) {
    .pillar .container .links{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media only screen and (min-width: 1788px) {
    .pillar .container .links{
        grid-template-columns: repeat(4, 1fr);
    }
}
.pillar .container .links li button{
    background: transparent no-repeat 35px center;
    border: 5.33px solid #000;
    cursor: pointer;
    font-family: "MetricHPE-Semibold";
    font-size: 2rem;
    line-height: 2.1875rem;
    min-height: 160px;
    padding: 0 30px 0 175px;
    text-align: left;
    background-size: 96px;
    width: 100%;
}
section.learnmore{
    background-color: #EFEFEF;
    margin-top: 32px;
    padding: 32px 48px;
}
section.learnmore h4{
    font-size: 2.5rem;
    line-height: 3rem;
    margin: 0px 0 24px;
}
section.learnmore .menu{ 
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
}
.menu li{ padding: 30px }

.modal-container{
    background: #e2e2e2 url('../../../public/background.jpg') no-repeat center top;
    background-size: 100% auto;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    min-height: calc(100vh - 40px);
    padding: 20px 90px 20px 86px;
}

.modal-container .content{
    display: grid;
    font-size:2.25rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    margin: 0 auto;
    padding: 0 40px;
}
.modal-container .content div{font-size: 2.1rem}
.modal-container .content div h3{
    font-family: "MetricHPE-SemiBold";
    font-size: 4rem;
    margin: 0 0 60px 0;
}
.modal-container .content div p{
    font-size: 1.5rem;
    line-height: 2rem;
}
.modal-container .content div p a{
    color:#01A982;
    font-family: "MetricHPE-SemiBold";
}
.modal-container .content div ul{
    margin-bottom: 20px;
}
.modal-container .content div ul li{
    font-size: 1.5rem;
    line-height: 2rem;
    list-style: disc;
    margin-left: 40px;
    margin-bottom: 30px;
}
.modal-container .content .disclaimer{
    margin-top: 115px;
}
.modal-container .content .disclaimer p{
    font-size: 1.25rem
}
.qrcode{
    display: flex;
    flex-direction: row;
}
.modal-container .content div .modallink.qrcode,
.modal-container .content div .modallink .qrcode{
    align-items: flex-start;
}
.modal-container .content div .qrcode img{
    margin-top: 8px;
}
.modal-container .content div .modallink{
    border: 5px solid #7630EA;
    margin-top: 10px;
    margin-bottom: 45px;
    padding: 20px;
}
.modal-container .content div .modallink span,
.qrcode span{
    color: #01A982;
    font-family: "MetricHPE-SemiBold";
    font-size: 2.25rem;
    margin-left: 20px;
    cursor: initial;
}
.modal-container .content div .modallink div{
    margin-bottom: 45px
}
.modal-container .content div .modallink div:last-child{
    margin-bottom: 0
}
.close-container{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 35px;
}
.close-container .close {
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    font-size: 0;
    position: relative;
    width: 60px;
    height: 60px;
}
.close-container .close:before, .close-container .close:after {
    content: "";
    position: absolute;
    top: 39.5px;
    left: 0;
    right: 0;
    height: 2px;
    background: #000;
    border-radius: 1px;
}
.close-container .close:before {
    transform: rotate(45deg);
}
.close-container .close:after {
    transform: rotate(-45deg);
}

@media only screen and (max-width: 769px) {
    .pillar.need .container{
        padding: 0 32px;
    }
    .pillar.need .container .content{
        grid-template-columns: repeat(1, 1fr)
    }
    .pillar.need .container .content h3.title{font-size: 3rem;}
    .pillar .container .content .hero.mobile{display: block;}
    .pillar .container .content .hero.d{display: none;}

    .pillar .container .links li button{
        background-size: 95px;
        background-position: 10px center;
        font-size: 1.5rem;
        padding-left: 120px;
    }

    section.learnmore .menu{
        grid-template-columns: repeat(2, 1fr)
    }
    section.learnmore .menu li{ padding-bottom: 50px }
}

.limit .pillar.need .container{
    padding: 0 32px;
}
.limit .pillar.need .container .content h3.title{font-size: 3rem;}

.limit .pillar .container .links li button{
    background-size: 95px;
    background-position: 10px center;
    font-size: 1.5rem;
    padding-left: 120px;
}

.limit section.learnmore .menu{
    grid-template-columns: repeat(2, 1fr)
}
.limit section.learnmore .menu li{ padding-bottom: 50px }