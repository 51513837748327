.verticals{
    background: url("../../../public/background.jpg") no-repeat 0 0;
    background-size: cover;
    padding: 0 48px;
    height: 100vh;
}
.verticals header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-top: 37px;
    margin-bottom: 65px;
    max-width: 100%;
}
.verticals header .logo{
    width: 140px;
}
.close{
    background: transparent url("./images/x.svg") no-repeat 0 0;
    border: none;
    color: #000;
    cursor: pointer;
    font-size: 30px;
    height: 28px;
    text-indent: -100000px;
    width: 31px;
}
.verticalsList{
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.375rem;
    padding: 0;
    margin: 0 auto;
    margin-top: 20px;
    justify-content:center;
}
.verticalsList li{
    cursor: pointer;
}
.verticalsList li h3{
    font-family: "MetricHPE";
    font-size: 2rem;
    line-height: 2.25rem;
    margin: 0;
    margin-bottom: 30px;
}
.verticals .gradient{
    background: linear-gradient(224.8deg, #00DEC8 -0.69%, #D221FF 99.66%);
    padding: 19px;
    max-width: 278px;
}
.verticals .gradient div{
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100%;
    max-width: 100%;
}
.verticals .gradient div img{
    width: 100%;
    max-width: 160px;
}
.verticals .gradient.publicsector{background: linear-gradient(224.8deg, #00DEC8 -0.69%, #D221FF 99.66%)}
.verticals .gradient.healthcare{background: linear-gradient(224.73deg, #8219F3 0.23%, #EB3474 50.76%, #FFC800 99.31%)}
.verticals .gradient.manufacturing{background: linear-gradient(223.56deg, #FF7A00 -44.63%, #FF8200 -12.11%, #FFA900 18.66%, #FFC200 28.23%, #FFCB00 32.86%, #F9CA02 34.73%, #E8C80B 43.39%, #CDC418 53.49%, #A7C02B 65.03%, #77BA43 76.57%, #3CB360 88.12%, #00AC7F 99.66%)}
.verticals .gradient.retail{background: linear-gradient(224.8deg, #3390D9 -0.69%, #0EC8CC 20.74%, #00DEC8 39.55%, #13DCB8 54.5%, #47D78F 75.62%, #9AD04E 99.66%)}
/*
.gradient-5{background: linear-gradient(224.73deg, #8219F3 0.23%, #EB3474 99.31%)}
.gradient-6{background: linear-gradient(224.8deg, #8219F3 -0.69%, #6E36EC 6.34%, #3390D9 26.41%, #0EC8CC 41.46%, #00DEC8 48.48%, #13DCB8 54.5%, #47D78F 65.54%, #9AD04E 81.59%, #FFC800 99.66%)}
*/

@media only screen and (max-width: 769px) {
    .verticalsList{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .verticalsList li h3{
        font-size: 1.7rem;
        margin-bottom: 20px;
    }
    .verticals .gradient div img{ 
        max-width: 125px;
    }
    
}

.limit .verticalsList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.limit .verticalsList li h3{
    font-size: 1.7rem;
    margin-bottom: 20px;
}
.limit .verticals .gradient div img{ 
    max-width: 125px;
}