.popup{
    position:absolute;
    top:0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 3;
}
.popup__inner{
    background: #fff;
    border-radius: 12px;
    /*box-shadow: inset 0 0 0.7em 0 var(--clr-neon), 0 0 1em 0 var(--clr-neon);*/
    color: #000;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    position: relative;
    max-height: 85%;
    -webkit-transform: translateX(-40%);
    -moz-transform: translateX(-40%);
    transform: translateX(-50%);
    width: 700px;

    -webkit-animation: fadein 1.3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1.3s; /* Firefox < 16 */
        -ms-animation: fadein 1.3s; /* Internet Explorer */
         -o-animation: fadein 1.3s; /* Opera < 12.1 */
            animation: fadein 1.3s;
}
.popup__close{
    background: transparent;
    border: none;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 30px;
    font-size: 20px;
    cursor: pointer;
}
.popup__inner-content{
    border-top: 1px solid #C6C9C9;
    padding: 20px;
    padding-top: 10px;
    overflow: auto;
}
.popup__inner-content::-webkit-scrollbar {
    width: 20px;
  }
.popup__inner-content::-webkit-scrollbar-thumb{
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    background: #909090;
    background-clip: padding-box;
    border-radius: 10px;
}

.popup__category{
    display: block;
    font-size: 18px;
    letter-spacing: 2px;
    margin: 20px 0;
    text-transform: uppercase;
    padding-left: 20px;
}
.popup h1{
    font-family: "MetricHPE-Bold";
    font-size: 40px;
    margin: 0;
    margin-bottom: 20px;
    padding-left: 20px;
}
.popup__inner h2{
    background: no-repeat center left;
    background-size: 28px 100%;
    font-family: "MetricHPE-Semibold", Arial, Helvetica, sans-serif;
    font-size: 18px;
}
.popup__requirements,
.popup__outcome{
    color: #282828;
    font-size: 18px;
    padding-top: 20px;
}
.popup__requirements{
    margin-top: 0;
    padding-top: 0;
}
.popup__outcome{
    border-top: 1px solid #C6C9C9;
    margin-top: 40px;
}
.popup__solution{
    border-top: 1px solid #C6C9C9;
    color: #282828;
    font-size: 18px;
    margin: 40px 0;
    margin-bottom: 0;
    padding: 20px 0;
}
.popup__inner-content img,
.popup__requirements-content img,
.popup__outcome-content img,
.popup__solution-content img {
    max-width: 100%;
}
.popup__requirements-content ul,
.popup__outcome-content ul,
.popup__solution-content ul {
    margin: 0;
    padding: 0;
    padding-left: 20px;
}
.popup__highlight{
    border: 10px solid #F68C6E;
    margin: 33px 0;
    padding: 35px 60px;
    text-align: center;

}
.popup__highlight span{
    display: block; 
    width: 100%;
}
.popup__footer{
    border-top: 1px solid #C6C9C9;
    padding-top: 20px;
    text-align: right;
}
.popup__footer .discoverMore{
    background: #17EBA0;
    border: none;
    border-radius: 10px;
    color: #000;
    cursor: pointer;
    font-family: "MetricHPE-Bold";
    font-size: 22px;
    height: 58px;
    margin-left:auto;
    margin-right: 15px;
    padding: 12px 0;
    width: 187px;
}