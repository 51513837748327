header.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-top: 40px;
    margin-bottom: 60px;
    max-width: 100%;
}
header.header nav{
    display: flex;
    align-items: center;
}
header.header .cityscape{
    background: #fff;
    border: 1px solid #000;
    border-radius: 2.667px;
    cursor: pointer;
    font-family: "MetricHPE-Medium";
    font-weight: 500;
    font-size: 1rem;
    margin-right: 20px;
    padding: 5.333px;
}
header.header .cityscape:hover {
    border: 0.667px solid #50B08B;
    background: #01A982;
    color: #fff;
}
header.header .back button{
    background: transparent url("../../assets/images/arrow-back.svg") no-repeat 0 0;
    border: 0;
    cursor: pointer;
    display: block;
    height: 28px;
    text-indent: -12000px;
    overflow: hidden;
    width: 45px;
}