.limit .search{ 
    left: 50%;
    transform: translateX(-50%);
    max-width: 1024px
}
.search{
    background: url("../../../public/background.jpg") no-repeat 0 0;
    background-size: cover;
    color: #000;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    overflow: hidden;
    
    width: 100%;
    z-index: 2;

    -webkit-animation: fadein .5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein .5s; /* Firefox < 16 */
        -ms-animation: fadein .5s; /* Internet Explorer */
         -o-animation: fadein .5s; /* Opera < 12.1 */
            animation: fadein .5s;
}
.search header.search-header{
    display: flex;
    flex-direction: column;
    align-items: normal;
    padding: 48px 48px 0 48px;
    max-width: 100%;
}
.search header .search__back{
    background: transparent url("../../assets/images/arrow-back.svg") no-repeat 0 0;
    border: none;
    cursor: pointer;
    height: 28px;
    text-indent: -100000px;
    width: 45px;
}
.search header .form-container{
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
}
.search header .form-container h2{
    color: #000;
    font-family: "MetricHPE-Semibold";
    font-size: 3rem;
    margin: 0;
}
.search__container{
    display: flex;
    justify-content: center;
    overflow-y: auto;
    height: calc(100vh - 200px);
    max-width: 100%;
    margin: auto;
    margin-top: 40px;
    padding: 0 56px 0 48px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.filters{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    align-items: center;
    
}
.filters span{
    font-family: "MetricHPE-Medium";
    font-size: 1rem;
    text-align: right;
}
.search__container .filters .select{
    background: #6DE7A6;
    border: none;
    border-radius: 3px;
    padding: 10px;
    min-width: 175px;
    font-weight: bold;
}
.search__container .filters [id$='-listbox']{
    top: auto
}
.search__container .searchlinksBox__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    row-gap: 3rem;
    height: max-content;
    margin-top: 0;
    margin-bottom: 1rem;
    max-width: 1280px;
}



@media only screen and (max-width: 769px) {
    .search header.search-header{
        padding: 32px 32px 0 32px;
    }
    .search header .form-container{
        flex-direction: column;
        margin-top: 20px;
    }
    .filters{
        grid-template-columns: 60px repeat(3, 1fr);
        margin-top: 15px;
    }
    .filters span{
        text-align: left;
    }
    .search__container .searchlinksBox__container{
        grid-template-columns: repeat(2, 1fr)
    }
}

.limit .search header.search-header{
    padding: 32px 32px 0 32px;
}
.limit .search header .form-container{
    flex-direction: column;
    margin-top: 20px;
}
.limit .filters{
    grid-template-columns: 60px repeat(3, 1fr);
    margin-top: 15px;
}
.limit .filters span{
    text-align: left;
}
.limit .search__container .searchlinksBox__container{
    grid-template-columns: repeat(2, 1fr)
}