.show{
    display: block !important;
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
}
.scrolldown{
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.50);
    cursor: pointer;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 24px;
    
    
    -webkit-animation: fadeIn 0.4s;
    animation: fadeIn 0.4s;
    display: flex;
    justify-content: center;
    padding: 16px 24px;
}
.scrolldown.hide{display: none}
.scrolldown span.readmore-arrow {
    background: url('./images/arrow-down.svg') no-repeat 0 0;
    color: #fff;
    font-family: "MetricHPE-Medium";
    font-size: 1rem;
    padding-left: 24px;
}
