.linksBoxItem{
    background: #F2F2F2;
    cursor: pointer;
    padding-bottom: 24px;
    width: 378px;
}
.linksBoxItem a{
    color: #fff;
    text-decoration: none;

}
.linksBoxItem a:hover{
    text-decoration: underline;
}
.linksBoxItem .header{
    position: relative;
}
.linksBoxItem .header img{
    display: block; 
    width: 378px;
    height: 212px;
}
.linksBoxItem .header .qrcode{
    background: #fff;
    position: absolute;
    top: 140px;
    right: 15px;
    padding: 5px;
}
.linksBoxItem .header .category{
    display: block;
    font-family: 'MetricHPE-Medium';
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin-top: 6px;
    margin-bottom: 5px;
    padding-left: 16px;
}
.linksBoxItem .body{
    margin-top: 16px;
    padding: 0 16px;
}
.linksBoxItem .body h3{
    font-family: "MetricHPE";
    font-size: 2.3rem;
    line-height: 2.25rem;
    margin: 0;
    margin-bottom: 7px;
}
.linksBoxItem .body h4{
    font-family: 'MetricHPE-Light';
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 32px 0 0 0;
}