.pillar .container{
    background-color: #fff;
    padding: 0px 48px 43px 48px;
}
.pillar .container.fade-in {
    -webkit-animation: fade-in 1.3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fade-in 1.3s; /* Firefox < 16 */
        -ms-animation: fade-in 1.3s; /* Internet Explorer */
         -o-animation: fade-in 1.3s; /* Opera < 12.1 */
            animation: fade-in 1.3s;
}
@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1 !important; }
}

.pillar .container .content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin: 0 auto;
}
.pillar .container .content img{margin-top:10px}
.pillar .container .content h3.title{
    font-size: 3.5rem;
    line-height: 3.75rem;
    font-family: "MetricHPE-Semibold";
    margin:0;
    margin-bottom: 80px;
    padding-bottom: 27px;
    position: relative;
}
.pillar .container .content h3.title::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 176px;
    left: 0;
    border-top: 9px solid #01A982;
}
.pillar .container .content-container ul{
    padding-left: 15px;
    list-style: disc;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2.5rem;
}
.pillar .container .content p{
    font-size: 1.5rem;
    line-height: 2rem;
}
.pillar .container .content .hero{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.pillar .container .content .hero img{
    max-width: 580px;
}
.pillar .assets{
    display: grid;
    grid-template-columns: repeat(3, 378px);
    gap: 1.5rem;
    row-gap: 3rem;
    margin: auto;
    margin-top: 0;
    padding: 0 48px;
    padding-bottom: 80px;
}
@media only screen and (min-width: 1685px) {
    .pillar .assets{
        grid-template-columns: repeat(auto-fit, 378px);
    }
}

@media only screen and (max-width: 769px) {
    .pillar .container{
        padding-left: 32px;
        padding-right: 32px;
    }
    .pillar .assets{
        padding: 0 32px 80px 32px;
        grid-template-columns: repeat(2, 340px)
    }
    .linksBoxItem{
        width: 340px;
    }
    .pillar .container .content{
        grid-template-columns: repeat(1, 1fr)
    }
    .pillar .container .content h3.title{font-size: 3rem;}
    .pillar .container .content .hero.mobile{display: block;}
    .pillar .container .content .hero.d{display: none;}
}

.limit .pillar .container{
    padding-left: 32px;
    padding-right: 32px;
}
.limit .pillar .assets{
    padding: 0 32px 80px 32px;
    grid-template-columns: repeat(2, 340px)
}
.limit .linksBoxItem{
    width: 340px;
}
.limit .pillar .container .content{
    grid-template-columns: repeat(1, 1fr)
}
.limit .pillar .container .content h3.title{font-size: 3rem;}
.limit .pillar .container .content .hero.mobile{display: block;}
.limit .pillar .container .content .hero.d{display: none;}